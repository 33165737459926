<template>
  <div class="ser">
    <div class="cen">
      <img src="../assets/sert.jpg" class="ims" alt="сертификат на окна и двери пвх">
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.ser {
  width: 90%;
  margin: 8vh auto;
}
.cen {
  display: flex;
  justify-content: center;
}
.ims {
  width: 70%;
}

@media (max-width: 767px) {

  .ims {
    width: 100%;
  }
}
</style>