<template>
  <div class="gp">
    <h1>Оконный профиль Vektor 70</h1>
    <p>
        <img class="iml" alt="пятикамерный стеклопакет" src="../assets/ve.jpg">
        Пятикамерный профиль с современной геометрией, монтажной шириной 70 мм и возможностью установки стеклопакета до 40 мм. Это оконная система сочетает в себе высокое качество исполнения с экономически выгодным решением - она идеально подойдет по техническим характеристикам как для массовой застройки, так и для частных заказчиков. Отлично подходит для регионов средней полосы.
    </p>
    <p>
        <b>Свойства.</b><br>
        Монтажная ширина - 70 мм <br>
        Максимальная толщина стеклопакета - 40 мм <br>
        Количество камер - 5 <br>
        Коэффициент сопротивления теплопередаче, м2°С/Вт - 0,72(с армирующим усилителем) <br> 
        Герметичность контуров уплотнения - соответствуют классу А <br>
        Уплотнители черногоь и серого цвета <br>
        Количество контуров уплотнения - 2 <br>
        Срок службы - более 40 лет
    </p>
    <p>
        <b>Преимущества.</b><br>
        Морозостойкость. Оснащается противовзломной фурнитурой. Штапики и доборные профили унифицированы с системой Grain-Prestige 70. Прочность сварных угловых соединений превышает нормированные показатели в 2,5 раза. 
    </p>
    <p>
        <b>Дизайн.</b><br>
        Современный вид пластикового окна.
    </p>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.gp {
    width: 90%;
    margin: 8vh auto;
}
h1 {
    font-size: 2vw;
}
.iml {
    float: left;
    width: 30vw;
    padding: 3vw;
}
p {
    font-size: 1vw;
    text-align: justify;
}

@media (max-width: 767px) {

    h1 {
        font-size: 23px;
    }
    .iml {
        width: 100%;
    }
    p {
        font-size: 14px;
    }
}
</style>