import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutView from '../views/AboutView.vue'
import GP70View from '../views/GP70View.vue'
import GL58View from '../views/GL58View.vue'
import Dob70View from '../views/Dob70View.vue'
import Ee60View from '../views/Ee60View.vue'
import Eo70View from '../views/Eo70View.vue'
import Ve58View from '../views/Ve58View.vue'
import Ve70View from '../views/Ve70View.vue'
import Np58View from '../views/Np58View.vue'
import Np70View from '../views/Np70View.vue'
import RequisitesView from '../views/RequisitesView.vue'
import CertificatesView from '../views/CertificatesView.vue'
import DeclarationsView from '../views/DeclarationsView.vue'
import JobsView from '../views/JobsView.vue'
import VacanciesView from '../views/VacanciesView.vue'
import ContactsView from '../views/ContactsView.vue'
import MenuView from '../views/MenuView.vue'
import ArticlesView from '../views/ArticlesView.vue'
import LamView from '../views/LamView.vue'
import PotView from '../views/PotView.vue'
import StekView from '../views/StekView.vue'
import PvhView from '../views/PvhView.vue'
import VentView from '../views/VentView.vue'
import ElemView from '../views/ElemView.vue'

import NotFoundComponent from '../views/NotFoundComponent.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView
  },
  {
    path: '/GP70',
    name: 'GP70',
    component: GP70View
  },
  {
    path: '/GL58',
    name: 'GL58',
    component: GL58View
  },
  {
    path: '/dob70',
    name: 'dob70',
    component: Dob70View
  },
  {
    path: '/ee60',
    name: 'ee60',
    component: Ee60View
  },
  {
    path: '/eo70',
    name: 'eo70',
    component: Eo70View
  },
  {
    path: '/ve58',
    name: 've58',
    component: Ve58View
  },
  {
    path: '/ve70',
    name: 've70',
    component: Ve70View
  },
  {
    path: '/np58',
    name: 'np58',
    component: Np58View
  },
  {
    path: '/np70',
    name: 'np70',
    component: Np70View
  },
  {
    path: '/requisites',
    name: 'requisites',
    component: RequisitesView
  },
  {
    path: '/certificates',
    name: 'certificates',
    component: CertificatesView
  },
  {
    path: '/declarations',
    name: 'declarations',
    component: DeclarationsView
  },
  {
    path: '/jobs',
    name: 'jobs',
    component: JobsView
  },
  {
    path: '/vacancies',
    name: 'vacancies',
    component: VacanciesView
  },
  {
    path: '/contacts',
    name: 'contacts',
    component: ContactsView
  },
  {
    path: '/menu',
    name: 'menu',
    component: MenuView
  },
  {
    path: '/articles',
    name: 'articles',
    component: ArticlesView
  },
  {
    path: '/lam',
    name: 'lam',
    component: LamView
  },
  {
    path: '/pot',
    name: 'pot',
    component: PotView
  },
  {
    path: '/stek',
    name: 'stek',
    component: StekView
  },
  {
    path: '/pvh',
    name: 'pvh',
    component: PvhView
  },
  {
    path: '/vent',
    name: 'vent',
    component: VentView
  },
  {
    path: '/elem',
    name: 'elem',
    component: ElemView
  },

  {
    path: '/:pathMatch(.*)*', 
    name: 'notfound',
    component: NotFoundComponent
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      }
    }
    return { 
      top: 0, 
      behavior: 'smooth',
    }
  },
})

export default router
