<template>
  <div class="gp">
    <h1>Как происходит вентиляция пластиковых окон?</h1>
    <p>
        <img class="iml" alt="вентиляция пластиковых окон" src="../assets/vent.jpg">
        При использовании пластиковых окон помещение должно иметь максимально качественный воздухообмен, в жилом здании это один из ключевых параметров. Стандартная система вентиляции в жилом помещении состоит из вытяжек на кухне, в ванной и туалете, имеются воздухообмен между щелями в простых окнах. Но если вы решили выбрать пластиковые окна, размеры и цены уже уточнили, важным этапом будет обеспечение таким окном правильной вентиляции. Это усложняется тем, что конструкция пластикового окна состоит из 2-3 контуров уплотнения. Также посадка стеклопакета в переплет достаточно плотная, отчего воздухообмен ухудшается.
    </p>
    <p>
        Низкий уровень вентиляции в помещении характерен некоторыми недостатками, влажность воздуха в доме повышается, на стеклах окон появляется конденсат. В дальнейшем на откосах окна будет появляться плесень и грибок. При использовании пластиковых окон в старых домах, плесень образуется куда быстрее, особенно заметно ее появление в ванных и детских комнатах. Правильный подбор окон с хорошей вентиляцией позволит существенно улучшить ситуацию в жилом доме, вы избавляетесь от углекислого газа, неприятных запахов и сможете правильно настроить влажность в помещении.
    </p>
    <p>
        Если вы выбираете пластиковые окна недорого, обратите внимание на их характеристики относительно влажности. Любое пластиковое окно хорошего производителя должно регулироваться по степени открытости. Представлено в 4 положения — полностью закрыто, с распахнутой створкой, с откинутой створкой и щелевое проветривание. Не рекомендуется часто использовать положение щелевого проветривание, при нем створка полностью отходит от рамы на расстояние до 3 мм. При длительном использовании повышается шумность в помещении, через щели попадает ненужная пыль. В зимнюю погоду такое проветривание на долгий период приведет к появлению сквозняка.
    </p>
    <p>
        Отличной альтернативой стандартным окнам считаются новые модели, называемые оконные ПШУ. Приточные Шумозащитные Устройства имеют в конструкции приточный клапан, может располагаться через стену возле окна. При монтаже приточного устройства непосредственно в окно придется выполнять процедуру в верхней части конструкции. В итоге воздух будет циркулировать в районе потолка и опускаться по всему периметру помещения.
    </p>
    <p>
        Если вы решаетесь купить окна пластиковые, цены могут также отличаться по наличию других особенностей. Например, сегодня используют окна с управлением и без управления притоком воздуха. Стоимость таких моделей не особо отличаются, но обеспечивают хорошую вентиляцию. Модели с управлением притока воздуха могут регулироваться как вручную, так и автоматически. Второй вариант более оптимальный, ведь регулировка происходит в зависимости от влажности воздуха, где поток воздуха автоматически увеличивается или уменьшается.
    </p>
    <p>
        Использование окон с ПШУ имеет ряд нюансов и других особенностей, например, не рекомендуется использовать систему в зимнюю пору. Если сквозь клапан будет проходить струя холодного воздуха в теплое помещение, результатом станет выход из строя самого клапана или его запотевание. Только специалисты помогут выбрать наиболее подходящий клапан и соответствующее окно.
    </p>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.gp {
    width: 90%;
    margin: 8vh auto;
}
h1 {
    font-size: 2vw;
}
.iml {
    float: left;
    width: 30vw;
    padding: 3vw;
}
p {
    font-size: 1vw;
    text-align: justify;
}

@media (max-width: 767px) {

    h1 {
        font-size: 23px;
    }
    .iml {
        width: 100%;
    }
    p {
        font-size: 14px;
    }
}
</style>