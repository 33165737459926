<template>
  <div class="job">
    <h1>Полезная информация</h1>
    <div class="row row-cols-1 row-cols-md-6 g-6">
        <div class="col">
            <router-link class="lin" to="/lam">
                <div class="card h-100">
                    <img src="../assets/lamin.jpg" class="card-img-top" alt="ламинированные окна">
                    <div class="card-body">
                    <h5 class="card-title">Ламинированные пластиковые окна</h5>
                    <p class="card-text">
                        <!-- Изготовление и монтаж окон, ПВХ дверей, алюминиевых оконных решений по адресу: г.Чебоксары, ул. Ленинского комсомола -->
                    </p>
                    </div>
                </div>
            </router-link>
        </div>
        <div class="col">
            <router-link class="lin" to="/pot">
                <div class="card h-100">
                    <img src="../assets/pot.jpg" class="card-img-top" alt="монтаж пластиковых окон">
                    <div class="card-body">
                    <h5 class="card-title">Почему пластиковые окна потеют?</h5>
                    <p class="card-text">
                        <!-- Изготовление и монтаж окон, ПВХ дверей, алюминиевых оконных решений по адресу: г.Чебоксары, ул. Ленинского комсомола -->
                    </p>
                    </div>
                </div>
            </router-link>
        </div>
        <div class="col">
            <router-link class="lin" to="/stek">
                <div class="card h-100">
                    <img src="../assets/stek.jpg" class="card-img-top" alt="стеклопакет">
                    <div class="card-body">
                    <h5 class="card-title">Стеклопакеты для пластиковых окон</h5>
                    <p class="card-text">
                        <!-- Изготовление и монтаж окон, ПВХ дверей, алюминиевых оконных решений по адресу: г.Чебоксары, ул. Ленинского комсомола -->
                    </p>
                    </div>
                </div>
            </router-link>
        </div>
        <div class="col">
            <router-link class="lin" to="/pvh">
                <div class="card h-100">
                    <img src="../assets/pvh.jpg" class="card-img-top" alt="алюминиевые окна">
                    <div class="card-body">
                    <h5 class="card-title">Как выбрать окна из ПВХ?</h5>
                    <p class="card-text">
                        <!-- Изготовление и монтаж окон, ПВХ дверей, алюминиевых конструкций по адресу: г.Чебоксары, ул. Ленинского комсомола -->
                    </p>
                    </div>
                </div>
            </router-link>
        </div>
        <div class="col">
            <router-link class="lin" to="/vent">
                <div class="card h-100">
                    <img src="../assets/vent.jpg" class="card-img-top" alt="вентиляция окон">
                    <div class="card-body">
                    <h5 class="card-title">Как происходит вентиляция пластиковых окон?</h5>
                    <p class="card-text">
                        <!-- Изготовление и монтаж окон, ПВХ дверей, алюминиевых конструкций по адресу: г.Чебоксары, Новый город -->
                    </p>
                    </div>
                </div>
            </router-link>
        </div>
        <div class="col">
            <router-link class="lin" to="/elem">
                <div class="card h-100">
                    <img src="../assets/elem.jpg" class="card-img-top" alt="фурнитура окна">
                    <div class="card-body">
                    <h5 class="card-title">Из чего состоит пластиковое окно?</h5>
                    <p class="card-text">
                        <!-- Изготовление и монтаж окон, ПВХ дверей, алюминиевых конструкций по адресу: г.Чебоксары, Мясокомбинатский пр-д, 117а -->
                    </p>
                    </div>
                </div>
            </router-link>
        </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.job {
  width: 90%;
  margin: 8vh auto;
}
h1 {
  font-size: 2vw;
  margin-bottom: 3vh;
}
p {
  margin-top: 2vw;
  font-size: 1.1vw;
  text-align: justify;
}
.row {
    margin-top: 4vh;
}
.col {
    margin-bottom: 15px;
}
.card-title, .lin {
    text-decoration: none;
    font-size: 1.1vw;
}
.card-text {
    font-size: 0.7vw;
    text-align: left;
}
.card:hover {
    transform: scale(1.01);
    box-shadow: 1px 1px 5px 5px rgba(185, 185, 185, 0.7);
    transition: all 0.1s ease;
}

@media (max-width: 767px) {

    h1 {
        font-size: 23px;
    }
    .job {
        height: auto;
    }
    p {
        font-size: 14px;
    }   
    .card-title {
        font-size: 16px;
    }
    .card-text {
        font-size: 14px;
    }

}
</style>