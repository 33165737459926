<template>
  <div class="gp">
    <h1>Оконный профиль NordProf 58</h1>
    <p>
        <img class="iml" alt="профиль Nordprof" src="../assets/np.jpg">
        <b>Трехкамерная система Nordprof 58</b>
    </p>
    <p>
        Трехкамерная система <b>Nordprof 58</b> — это качественное и экономически выгодное решение в вопросе выбора ПВХ-профиля. Рекомендуется использовать при остеклении жилых, офисных помещений, объектах капитального строительства и т.п. <br>
        В системе <b>Nordprof 58</b> предусмотрены усиленные технологические выступы для надежного крепления фурнитуры. Прочность сварных угловых соединений превышает нормированные показатели в 2,5 раза. Возможность установки замкнутого армирования в раму, позволяет создавать нестандартные конструкции: витражи, офисные перегородки и др. (при условии соблюдения статистических расчетов). <br>
        Система <b>Nordprof 58</b> имеет в своем составе основные профили: рама, импост и створка в двух вариантах исполнения — с удалением фурнитурного паза 9 и 13 мм. Штапики и доборные профили унифицированы с системой Grain-Lider 58.
    </p>
    <p>
        <b>Краткие технические характеристики системы:</b><br>
        Класс профиля по ГОСТ 30673-2013 - “В” <br>
        Ширина профиля - 58 мм <br>
        Оптическая высота - 63 мм <br>
        Количество внутренних камер: рама — 3, створка — 3, импост — 3 <br>
        Удаленность фурнитурного паза - 9 и 13 мм <br>
        Количество контуров уплотнения - 2 <br>
        Максимальная толщина стеклопакета - 32 мм <br>
        Морозостойкость - контрольная нагрузка — 55°С <br>
        Сопротивление теплопередаче - 0,66 м2 Х°С/Вт <br>
        Прочность сварных угловых соединений - 250% (более чем в два раза превышает нормируемые показатели) <br>
        Долговечность - 40 лет <br>
        Цвет уплотнителя - черный, серый <br>
        Сертификация - Сертификат соответствия <br>
        Пожаробезопасность - отвечает всем требованиям пожарной безопасности. Сертификат соответствия
    </p>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.gp {
    width: 90%;
    margin: 8vh auto;
}
h1 {
    font-size: 2vw;
}
.iml {
    float: left;
    width: 30vw;
    padding: 3vw;
}
p {
    font-size: 1vw;
    text-align: justify;
}

@media (max-width: 767px) {

    h1 {
        font-size: 23px;
    }
    .iml {
        width: 100%;
    }
    p {
        font-size: 14px;
    }
}
</style>