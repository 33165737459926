<template>
  <div class="gp">
    <h1>Из чего состоит пластиковое окно?</h1>
    <p>
        <img class="iml" alt="элементы пластиковых окон" src="../assets/elem.jpg">
        Состав современного пластикового окна предполагает наличие нескольких конструктивных элементов, которые могут отличаться по размерам, сложности, материалу и другим показателям. Любой, кто выбирает пластиковые окна в Москве и других городах, должен знать конструкцию и основные элементы окна. Стандартное окно из ПВХ состоит из следующих элементов:
        <ul>
            <li>
                ПВХ профиль — используется для создания металлопластиковых окон различной сложности, основной элемент;
            </li>
            <li>
                фурнитура для окон ПВХ, упрощает использование створок окон;
            </li>
            <li>
                уплотнители;
            </li>
            <li>
                стеклопакеты — различаются по толщине, качеству и другим показателям;
            </li>
            <li>
                стальной каркас с фиксированной толщиной, монтируется в ПВХ профиль при производстве окна на заказ. Улучшает жесткость конструкции.
            </li>
        </ul>
    </p>
    <p>
        Большинство окон должны открываться, поэтому чтобы обеспечить это приходиться создавать конструкцию с неподвижной рамой и подвижной створки. На саму створку уже вставлен стеклопакет. И главное отличиепластиковых окон от деревянных именно в наличии системы с одинарной створкой, когда деревянные модели предполагают двойные створки.
    </p>
    <p>
        <b>ПВХ профиль.</b> <br> 
        Считается главной особенностью любого окна, такой компонент обладает камерами, в зависимости от модели их от 3 до 8-ми. Сквозные внутренние камеры выполняют роль по улучшению теплоизоляционных свойств, внутри устанавливаются несколько теплоизоляционных прослоек. <br>
        Также внутренняя часть ПВХ окон имеет армирующий вкладыш из оцинкованной стали, надежно закрепленный шурупами. Задача такого вкладыша — защитить поверхность профиля от ветра и различных погодных условий.
    </p>
    <p>
        <b>Стеклопакет.</b> <br> 
        Если вы выбираете такой продукт как окна пластиковые, расчет стоимости будет происходить на основании нескольких показателей. Одним из них считается характеристики стеклопакета. Представляет собой герметичную конструкцию из нескольких стекол, между которыми находиться алюминиевая рамка. Рамка в свою очередь соединена со стеклами герметиком. Вообще работа данного стеклопакета достаточно сложная, создавая особую среду, между стеклами стеклопакета располагается осушенный воздух.
    </p>
    <p>
        <b>Фурнитура.</b> <br> 
        Представляет собой набор из нескольких металлических частей, задача которых — создать единый механизм по контуру окна. Фурнитура позволит дать возможность окну правильно открываться и закрываться. Комплект фурнитуры обычно представлен деталями для возможности не только открыть окно, но и произвести проветривание. Материалом оконной фурнитуры выступает сталь с покрытием от коррозии.
    </p>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.gp {
    width: 90%;
    margin: 8vh auto;
}
h1 {
    font-size: 2vw;
}
.iml {
    float: left;
    width: 30vw;
    padding: 3vw;
}
p, li {
    font-size: 1vw;
    text-align: justify;
}

@media (max-width: 767px) {

    h1 {
        font-size: 23px;
    }
    .iml {
        width: 100%;
    }
    p, li {
        font-size: 14px;
    }
}
</style>