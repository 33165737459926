<template>
  <div class="gp">
    <h1>Оконный профиль Enwin Eco 60</h1>
    <p>
        <img class="iml" alt="окна и двери пвх" src="../assets/en.jpg">
        <b>Преимущества:</b>
    </p>
    <h5>ТЕПЛО</h5>
    <p>
        Благодаря 4-х камерному профилю 60мм
    </p>
    <h5>ТИХО</h5>
    <p>
        Возможна установка 2-х камерного стеклопакета толщиной до 32мм
    </p>
    <h5>ДОЛГОВЕЧНО</h5>
    <p>
        До 40 лет гарантии на профиль
    </p>
    <h5>ЭСТЕТИЧНО</h5>
    <p>
        Профили с гладкой глянцевой поверхностью устойчивы к УФ-излучению и перепаду температур
    </p>
    <h5>БЕЗОПАСНО</h5>
    <p>
        Благодаря использованию высококачественного сырья (экспертное заключение ФГБУ "Центр госсанэпиднадзора)
    </p>  
    <br><br>  
    <p>
        <b>Краткие технические характеристики системы:</b> <br>
        Ширина профиля - 60 мм <br>
        Количество камер - 4 <br>
        Коэффициент сопротивления теплопередаче - 0,68 м²С/Вт <br>
        Толщина стеклопакета - до 40 мм <br>
        2 контура уплотнения <br>
        Долговечность профиля - 40 лет <br>
        Цвет уплотнителя - черный, серый
    </p>
    <p>
        ОЧИСТКА ОКОН ПОСЛЕ МОНТАЖА <br>
        После установки окна следует очистить его от оставшихся загрязнений, используя специализированные чистящие средства.
    </p>
    <p>
        УДАЛЕНИЕ ЗАГРЯЗНЕНИЙ С ПЛАСТИКОВЫХ ПРОФИЛЕЙ <br>
        Для очистки поверхностей профиля применяются лёгкие растворители, имеющиеся у поставщиков окон, или бытовые моющие средства (мыльный раствор) - с их помощью удаляются загрязнения от пыли и дождя.
    </p>
    <p>
        УХОД ЗА ФУРНИТУРОЙ <br>
        Для ухода за фурнитурой используются средства, не разрушающие защиту от коррозии. Если оконная ручка разболталась, рекомендуем Вам приподнять декоративную планку, находящуюся под ручкой, повернуть её из вертикального в горизонтальное положение и затянуть винты.
    </p>
    
    <p>
        ОТВОД ВОДЫ <br>
        В нижней части оконной рамы находятся водоотводящие отверстия, отвечающие за отвод скапливающейся в окне влаги. Следует проверять чистоту данных отверстий два-три раза в год, удаляя из них загрязнения, препятствующие отводу воды.
    </p>
    <p>
        КОНТРОЛЬ УРОВНЯ ВЛАЖНОСТИ <br>
        Влага может выпадать в виде конденсата на самых холодных участках окна - стеклопакетах. Советуем Вам проветривать помещение 1-2 раза в день.
    </p>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.gp {
    width: 90%;
    margin: 8vh auto;
}
h1 {
    font-size: 2vw;
}
.iml {
    float: left;
    width: 30vw;
    padding: 3vw;
}
p {
    font-size: 1vw;
    text-align: justify;
}
h5 {
    font-size: 1.5vw;
    color: #003856;
}

@media (max-width: 767px) {

    h1 {
        font-size: 23px;
    }
    h5 {
        font-size: 21px;
    }
    .iml {
        width: 100%;
    }
    p {
        font-size: 14px;
    }
}
</style>