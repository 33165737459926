<template>
  <div class="gp">
    <h1>Оконный профиль Grain-Prestige 70</h1>
    <p>
        <img class="iml" alt="оконный профиль" src="../assets/grain.jpg">
        Премиальная оконная система <b>Grain-Prestige</b> обладает повышенными эксплуатационными характеристиками, что позволяет применять ее во всех климатических зонах, используя для жилых, офисных помещений, детских и медицинских учреждений. Окна из этой системы, обладая высочайшими теплоизоляционными и звукоизолирующими свойствами, прекрасно впишутся в интерьер любой квартиры или загородного дома и удовлетворят вкус самого взыскательного покупателя.
        
    </p>
    <p>
        Отличительной чертой профильной системы <b>Grain-Prestige</b>, в первую очередь, является то, что все профили, включая импост, имеют пять внутренних камер. Это существенно улучшает теплоизоляционные характеристики окна.
    </p>
    <p>
        Удаленность фурнитурного паза 13 мм позволяет устанавливать противовзломную фурнитуру.
    </p>
    <p>
        Дополнительные усилительные перегородки, технологические выступы во внутренних камерах профиля увеличивают прочность крепления запорных и несущих элементов, сокращают количество мостиков холода. Благодаря универсальности внутренней геометрии рамы появилась возможность установки замкнутого армирования. Это позволяет изготавливать нестандартные конструкции, безопасные в эксплуатации (при условии соблюдения статистических расчетов).
    </p>
    <p>
        <b>Свойства.</b><br>
        Монтажная ширина - 70 мм <br>
        Максимальная толщина стеклопакета - 40 мм <br>
        Количество камер - 5. <br>
        Коэффициент сопротивления теплопередаче, м2°С/Вт - 0,8(с армирующим усилителем). <br>
        Герметичность контуров уплотнения - соответствуют классу А. <br>
        Количество контуров уплотнения - 2. <br>
        Уплотнители черного и серого цвета. <br>
        Срок службы - более 40 лет.
    </p>
    <p>
        <b>Преимущества.</b><br>
        Морозостойкое исполнение. Экологичность и пожаробезопасность.
    </p>
    <p>
        <b>Дизайн.</b><br>
        Скругленные внешние контуры придают пластиковому окну элегантный внешний вид.
    </p>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.gp {
    width: 90%;
    margin: 8vh auto;
}
h1 {
    font-size: 2vw;
}
.iml {
    float: left;
    width: 30vw;
    padding: 3vw;
}
p {
    font-size: 1vw;
    text-align: justify;
}
@media (max-width: 767px) {

    h1 {
        font-size: 23px;
    }
    .iml {
        width: 100%;
    }
    p {
        font-size: 14px;
    }
}
</style>