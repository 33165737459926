<template>
  <div class="gp">
    <h1>Оконный профиль NordProf 70</h1>
    <p>
        <img class="iml" alt="пятикамерные окна" src="../assets/np2.jpg">
        <b>Пятикамерный профиль Nordprof 70</b>
    </p>
    <p>
        Доступный пятикамерный профиль с проверенной геометрией, монтажной шириной 70 мм и возможностью установки стеклопакета до 40 мм. <br>
        Эта оконная система сочетает в себе высокое качество исполнения с экономически выгодным решением — она идеально подходит по техническим характеристикам как для массовой застройки, так и для частных заказчиков. Отлично подходит для регионов средней полосы. <br>
        В системе <b>Nordprof 70</b> предусмотрены усиленные технологические выступы для надежного крепления фурнитуры. Прочность сварных угловых соединений полностью соответствует всем нормированным показателям. Окна из профиля <b>Nordprof 70</b> оснащаются противовзломной фурнитурой благодаря смещению оси фурнитурного паза на 13 мм. <br>
        Система <b>Nordprof 70</b> имеет в своем составе основные профили: рама, импост и створка. Штапики, доборные профили и оснастка унифицированы с системой Grain-Prestige 70.
    </p>
    <p>
        <b>Краткие технические характеристики системы:</b><br>
        Класс профиля по ГОСТ 30673-2013 - “В” <br>
        Ширина профиля - 70 мм <br>
        Оптическая высота - 63 мм <br>
        Количество внутренних камер: рама — 5, створка — 5, импост — 5 <br>
        Удаленность фурнитурного паза - 13 мм <br>
        Количество контуров уплотнения - 2 <br>
        Максимальная толщина стеклопакета - 40 мм <br>
        Морозостойкость - контрольная нагрузка — 55°С <br>
        Сопротивление теплопередаче - 0,72 м2 Х°С/Вт <br>
        Прочность сварных угловых соединений - соответствует всем нормируемым показателям <br>
        Долговечность - 40 лет <br>
        Цвет уплотнителя - черный, серый <br>
        Сертификация - Сертификат соответствия <br>
        Пожаробезопасность - отвечает всем требованиям пожарной безопасности. Сертификат соответствия
    </p>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.gp {
    width: 90%;
    margin: 8vh auto;
}
h1 {
    font-size: 2vw;
}
.iml {
    float: left;
    width: 30vw;
    padding: 3vw;
}
p {
    font-size: 1vw;
    text-align: justify;
}

@media (max-width: 767px) {

    h1 {
        font-size: 23px;
    }
    .iml {
        width: 100%;
    }
    p {
        font-size: 14px;
    }
}
</style>