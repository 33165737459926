<template>
  <div class="k1">
    <h1>Контакты</h1>
    <h3>ООО "ОКНА ЛИДЕР"</h3>
    <p>
      <b>Адрес:</b> 428000, Чувашская Республика - Чувашия, г. Чебоксары, Машиностроителей проезд, д. 1, корпус 2
    </p>
    <p>
      <b>Телефон:</b> <a class="tel" href="tel:+78352228021">+7 (8352) 22-80-21</a>, <a class="tel" href="tel:+79170788021">+7 (917) 078-80-21</a>
    </p>
    <p>
      Главный бухгалтер: <a class="tel" href="tel:+78352271571">+7 (8352) 271-571</a>
    </p>
    <p>
      <b>Email:</b> <a href="mailto:oknapvh21@mail.ru">oknapvh21@mail.ru</a>, <a href="mailto:chzbk.buh@mail.ru">chzbk.buh@mail.ru</a> (бухгалтерия)
    </p>
    <p>
      Работаем по будням: ПН-ПТ с 7:00 до 17:00
    </p> 
    <div class="map">
      <div style="position:relative;overflow:hidden;"><a href="https://yandex.ru/maps/org/proizvodstvennaya_kompaniya_okna_lider/23019213161/?utm_medium=mapframe&utm_source=maps" style="color:#eee;font-size:12px;position:absolute;top:0px;">Производственная компания Окна Лидер</a><a href="https://yandex.ru/maps/45/cheboksary/category/plastic_products/184106946/?utm_medium=mapframe&utm_source=maps" style="color:#eee;font-size:12px;position:absolute;top:14px;">Пластмассовые изделия в Чебоксарах</a><a href="https://yandex.ru/maps/45/cheboksary/category/windows/184107727/?utm_medium=mapframe&utm_source=maps" style="color:#eee;font-size:12px;position:absolute;top:28px;">Окна в Чебоксарах</a><iframe src="https://yandex.ru/map-widget/v1/?ll=47.307241%2C56.123298&mode=search&oid=23019213161&ol=biz&z=16.37" width="1215" height="400" frameborder="1" allowfullscreen="true" style="position:relative;"></iframe></div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.k1 {
  width: 90%;
  margin: 8vh auto;
}
h1 {
  font-size: 2vw;
  margin-bottom: 3vh;
}
h3 {
  margin-top: 2vw;
  font-size: 2vw;
}
p {
  font-size: 1.1vw;
  text-align: justify;
}
a {
  font-size: 1.1vw;
}
.map {
  margin-top: 3vw;
}
iframe {
  height: 90vh;
  width: 100%;
}

@media (max-width: 767px) {

  h1 {
    font-size: 23px;
  }
  h3 {
    font-size: 24px;
    margin-top: 30px;
  }
  p, a {
    font-size: 14px;
  }

}
</style>