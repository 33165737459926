<template>
  <div class="about">
    <h1>О фабрике</h1>
    <p>
      Фабрика <b>«Окна Лидер»</b> успешно развивается с 2015 года. <br>
      Мы предоставляем полный спектр услуг: от замера - до изготовления готовых оконных конструкций, а именно: замер оконных проемов, заключение договора, изготовление конструкции, доставка готовых конструкций, демонтаж старых окон и монтаж новых, монтаж подоконников, отливов и пластиковых откосов, установка москитных сеток. Монтаж производится по ГОСТу. <br>
      Весь цикл изготовления изделий осуществляется нами на собственной высокотехнологичной базе с использованием качественных и проверенных материалов и комплектующих - сотрудничаем с известными производителями алюминиевых систем и пластикового профиля (торговые марки «Enwin», Grain и др.). <br>
      Достойные эстетические и технические характеристики нашей продукции обеспечиваются с помощью применения европейского оборудования и профессионализма сотрудников нашей фабрики. В процессе изготовления пластиковых и алюминиевых конструкций участвуют инженерно-технические специалисты с внушительным стажем работы в данной сфере, а продажи ведуться нашими менеджерами, помогающими каждому клиенту подобрать нужные изделия на основе индивидуальных предпочтений. На производственных площадях нашей фабрики все технологические процессы автоматизированы и контролируются компьютерными установками на всех стадиях. Это также гарантирует высокие показатели качества изготавливаемой продукции, идеальную геометрию и соответствие ГОСТам и СНиПам. <br> 
      Вся продукция компании «Окна Лидер» сертифицирована на соответствие требованиям безопасности и по своим техническим характеристикам находится на уровне мировых стандартов. <br>
      <br>
      Обратившись к нам, вы сможете комплексно решить задачи с остеклением вашего объекта, поскольку наша компания имеет весь необходимый сервисный и производственный комплекс. Это дает нам возможность четко контролировать качество нашей продукции на всех этапах, начиная от изготовления и заканчивая профессиональным монтажом. <br>
      Сотрудничая с нами, вы избегаете посредников. <br> 
      На все работы наших специалистов предоставляется гарантия. <br>
      <b>Чтобы начать процесс замены окон, нужно только позвонить по телефону: +7 (917) 078-80-21 и записаться на замер. Замер в нашей компании бесплатный.</b>
      
    </p>
    <p>
      <b>Основные направления деятельности нашей фабрики:</b>
      <ul>
        <li>
          Производство алюминиевых конструкций – остекление лоджий и балконов, их эстетичная и качественная отделка.
        </li>
        <li>
          Изготовление пластиковых окон и дверей ПВХ.
        </li>
        <li>
          Производство ламинированных цветных окон ПВХ.
        </li>
        <li>
          Качественное фасадное остекление.
        </li>
        <li>
          Услуги по монтажу, ремонту, гарантийному и сервисному обслуживанию алюминиевых и пластиковых конструкций.
        </li>
      </ul>
    </p>
    <p>
      <b>Преимущества фабрики «Окна Лидер»:</b>
      <ul>
        <li>
          Наличие современного оборудования, использования качественных материалов, которые позволяют выпускать современную продукцию, отвечающую самым строгим технологическим стандартам.
        </li>
        <li>
          Все наши сотрудники – это квалифицированные и опытные специалисты, которые максимально ответственно относятся к своей работе.
        </li>
        <li>
          Множество пластиковых и алюминиевых решений, которые позволяют предлагать разнообразные варианты для эстетичного и практичного оформления фасадов.
        </li>
        <li>
          Мы умеем находить рациональные и эффективные решения для каждого заказчика.
        </li>
        <li>
          Предоставление гарантии на все изделия и услуги.
        </li>
      </ul>
    </p>
    <p>
      Широчайший ассортимент продукции позволяет нам предлагать оконные решения от простых до элитных! <br>
      Вы можете выбрать окна, которые подходят Вам и Вашему бюджету, а наши менеджеры помогут в выборе, расскажут об отличиях и преимуществах продукции. <br>
      С нашей помощью Вы легко подчеркнете свой собственный вкус и придадите своей квартире, дому неповторимый внешний вид и уют на долгие годы!
    </p>
    <div class="imd">
      <img src="../assets/pro.jpg" class="imzv" alt="фабрика окон">
      <img src="../assets/pro2.jpg" class="imzv" alt="алюминиевые фасады">
      <img src="../assets/pro3.jpg" class="imzv" alt="двери пвх">
      <img src="../assets/pro4.jpg" class="imzv" alt="пластиковые окна">
    </div>
    <div class="imd2">
      <img src="../assets/pro5.jpg" class="imlab" alt="пластиковые двери">
      <img src="../assets/pro6.jpg" class="imlab" alt="окна пвх">
      <img src="../assets/pro7.jpg" class="imlab" alt="стеклопакеты">
      <img src="../assets/pro8.jpg" class="imlab" alt="окна">
    </div>
  </div>
</template>

<script>
</script>

<style scoped>
.about {
  width: 90%;
  margin: 8vh auto;
}
h1 {
  font-size: 2vw;
  margin-bottom: 3vh;
}
p, li {
  font-size: 1vw;
  text-align: justify;
}
.imd {
  display: flex;
  justify-content: space-between;
  margin-top: 4vh;
}
.imd2 {
  display: flex;
  justify-content: space-between;
  margin-top: 4vh;
}
.imzv, .imlab {
  width: 21vw;
}

@media (max-width: 767px) {

  h1 {
    font-size: 23px;
  }
  p, li {
    font-size: 14px;
  }
  .imd {
    display: block;
  }
  .imd2 {
    display: block;
    margin-top: 0.1vh;
  }
  .imzv, .imlab  {
    width: 100%;
    margin-bottom: 10px;
  }
}
</style>
