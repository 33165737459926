<template>
  <div class="gp">
    <h1>Оконный профиль Добрыня 70(А)</h1>
    <h5>Пятикамерная система ДОБРЫНЯ</h5>
    <p>
        <img class="iml" alt="пластиковые окна" src="../assets/dob.jpg">
        <b>Добрыня 70(А)</b> – практичное решение установки безупречной оконной системы. <br>
        Заботясь о красоте и надежности при обустройстве дома, важную роль играет установка качественной оконной системы. Уникальные технические характеристики имеет пятикамерная система <b>Добрыня 70(А)</b>.
        
    </p>
    <p>
        <b>Неоспоримые преимущества профиля Добрыня 70(А)</b>
    </p>
    <p>
        Вам обязательно нужно купить профиль Добрыня если вы за:
        <ul>
            <li>
                Высококачественную продукцию – 75% изделия состоит из компонентов высокого качества, произведенных в РФ.
            </li>
            <li>
                Долговечность в использовании – профиль Добрыня 70 (А) характеризуется высокими физико-механическими свойствами, что означает повышенную устойчивость к ультрафиолетовому излучению.
            </li>
            <li>
                Надежность – ударопрочность, гибкость при монтажных работах, чтобы изделие служило долгие годы и в процессе эксплуатации не изменяло свои эстетические качества.
            </li>
            <li>
                Соответствие стандартам – при производстве продукции осуществляется контроль качества согласно требованиям ГОСТа 30673-2013.
            </li>
            <li>
                Прекрасный дизайн – заботясь об эстетической стороне, специалисты создали безупречный белый глянцевый дизайн поверхности изделия. Такое исполнение добавляет благородную роскошь и нарядность окнам.
            </li>
        </ul>
    </p>
    <p>
        Выгода при покупке пятикамерной системы Добрыня 70(А) очевидна, ведь технология производства профиля уникальна и имеет высокие потребительские свойства. Изделие зарекомендовало себя с наилучшей стороны у множества клиентов, сотрудничающих с компанией на протяжении 20 лет!
    </p>
    <p>
        Профиль Добрыня 70 (А) – ценное вложение в обустройство жилья
    </p>
    <p>
        Продукция отлично подходит как для заказчиков крупных компаний, занимающихся застройками жилых комплексов, так и для физических лиц, желающих сделать свой дом или квартиру уютной. <br>
        Покупая профиль Добрыня 70 (А), вы получаете экономически выгодное приобретение, сочетающее в себе отличное качество исполнения. Продукция обладает высокой морозостойкостью, идеально подходит для всех климатических зон РФ.
    </p>
    <p>
        <b>Оконная система Добрыня 70 (А) это:</b>
    </p>
    <ul>
        <li>
            прочность – все соединения и выступы усиленны и надежны, их показатели выше нормы;
        </li>
        <li>
            безопасность – изделие обладает противовзломной фурнитурой, что обеспечит охрану жилища во время вашего отсутствия;
        </li>
        <li>
            долгий срок эксплуатации – компания гарантирует, что оконная система прослужит до 40 лет. Таким показателем может похвастаться далеко не каждый производитель!
        </li>
    </ul>
    <p class="ital">
        <b>Система Добрыня 70(А) – это превосходное качество продукции по выгодной цене!</b>
    </p>
    <p>
        За основу профиля Добрыня взята 5-ти камерная система с монтажной глубиной 70 мм, которая обладает характеристиками, превышающими показатели 58-ой системы. На протяжении 20-ти лет эта система зарекомендовала себя самым лучшим образом.
    </p>
    <p>
        Технологи компании «Грайн» разработали специальную рецептуру для профиля Добрыня. Она на 75% состоит из высокачественных компонентов произведенных в нашей стране. Этот профиль сделан в России и сделан для России.
    </p>
    <p>
        В профиле Добрыня усилены физико-механические свойства – увеличена стойкость с UV-излучению, повышена гибкость профиля, а также ударопрочность, усилен контроль качества согласно новому ГОСТу 30673-2013. Добрыня отличается и улучшеными потребительскими свойствами – профиль имеет белую гладкую глянцевую поверхность, что делает окна из этого профиля нарядными.
    </p>
    <p>
        Новый продукт в серии профилей класса А – Добрыня – пятикамерная система с современной геометрией, монтажной шириной 70 мм и возможностью установки стеклопакета до 40 мм.
    </p>
    <p>
        Этот профиль сочетает в себе высокое качество исполнения с экономически выгодным решением – по техническим характеристикам она идеально подходит как для массовой застройки, так и для частных заказчиков. Отлично подходит для регионов средней полосы (для всех климатических зон России).
    </p>
    <p>
        В системе Добрыня предусмотрены усиленные технологически выступы для надежного крепления фурнитуры. Прочность сварных угловых соединений превышает нормированные показатели в 2,5 раза.
    </p>
    <p>
        Окна из профиля Добрыня оснащаются противовзломной фурнитурой благодаря смещению оси фурнитурного паза на 13 мм. Система Добрыня имеет в своем составе основные профили: рама, створка, импост. Штапики, доборные профили и остастка унифицированы с системой Grain-Prestige 70.
    </p>
    <p>
        <b>Краткие технические характеристики системы:</b><br>
        Класс профиля по ГОСТ 30673-99 - “А” <br>
        Монтажная глубина - 70 мм <br>
        Морозостойкость - II класс (М) <br>
        Удаление фурнитурного паза - 13 мм <br>
        Коэффициент сопротивления теплопередаче - 0,72 м2С/Вт <br>
        Класс сопротивления теплопередаче - 4 <br>
        Долговечность - 40 лет <br>
        Армирующий профиль - артикул 207, 203
    </p>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.gp {
    width: 90%;
    margin: 8vh auto;
}
h1 {
    font-size: 2vw;
}
.iml {
    float: left;
    width: 30vw;
    padding: 3vw;
}
p, li {
    font-size: 1vw;
    text-align: justify;
}
h5 {
    font-size: 1.5vw;
    color: #003856;
}
.ital {
    font-style: italic;
}
@media (max-width: 767px) {

    h1 {
        font-size: 23px;
    }
    h5 {
        font-size: 21px;
    }
    .iml {
        width: 100%;
    }
    p, li {
        font-size: 14px;
    }
}
</style>