<template>
  <div class="req">
    <h1>Карта партнера</h1>
    <h2>ООО "ОКНА ЛИДЕР"</h2>
    <p>
        <b>ИНН:</b>
        2130168953
    </p>
    <p>
        <b>КПП:</b>
        213001001
    </p>
    <p>
        <b>ОГРН:</b>
        1162130054228
    </p>
    <p>
        <b>ОКПО:</b>
        00882224
    </p>
    <p>
        <b>Расчетный счет:</b>
        40702810211000001061
    </p>
    <p>
        <b>Банк:</b>
        ЧУВАШСКИЙ РФ АО "РОССЕЛЬХОЗБАНК"
    </p>
    <p>
        <b>БИК:</b>
        049706752
    </p>
    <p>
        <b>Корр. счет:</b>
        30101810600000000752
    </p>
    <p>
        <b>Юридический адрес:</b>
        428000, Чувашская Республика - Чувашия, г.Чебоксары, пр.Машиностроителей, д.1 пом.1
    </p>
    <p>
        <b>Телефон:</b>
        (8352) 22-80-21 отдел продаж (начальник производства) oknapvh21@mail.ru, (8352) 27-15-81 бухгалтерия chzbk.buh@mail.ru
    </p>
    <p>
        <b>Директор:</b>
        Алексеев Владимир Николаевич
    </p>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.req {
    width: 90%;
    margin: 8vh auto;
}
h1 {
    font-size: 2vw;
}
h2 {
    font-size: 1.8vw;
    margin-top: 7vh;
    margin-bottom: 2vh;
}
p {
    font-size: 1.2vw;
}

@media (max-width: 767px) {

    h1 {
        font-size: 23px;
    }
    h2 {
        font-size: 21px;
    }
    p {
        font-size: 14px;
    }
}
</style>