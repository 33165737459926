<template>
  <div class="nf">
    <h1>Данной страницы не существует</h1>
    <div class="nc">
      <router-link to="/">
        <button>
          Перейти на главную страницу
        </button>
      </router-link> 
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.nf {
  height: 100vh;
}
h1, button {
  text-align: center;
  margin-top: 10vh;
}
.nc {
  display: flex;
  justify-content: center;
}
</style>