<template>
  <div class="gp">
    <h1>Как выбрать окна из ПВХ?</h1>
    <p>
        <img class="iml" alt="пвх окна" src="../assets/pvh.jpg">
        Современные варианты окон отличаются широким выбором моделей из разных материалов, пластиковые окна уже используются в большинстве помещений. Основные задачи, стоящие перед любым окном это обеспечение надлежащей защиты и качества стекла. Перед тем, как купить пластиковые окна, следует позаботиться о выборе качественного производителя, даже несмотря на то, что большинство ПВХ окон создаются по похожей технологии с применением одинаковых материалов. После подбора хорошей фирмы, продающей окна, следующий этап — характеристики окна. Покупателю важно обращать внимание на:
        <ul>
            <li>
                профиль;
            </li>
            <li>
                фурнитуру;
            </li>
            <li>
                способ монтажа окна;
            </li>
            <li>
                качество сборки;
            </li>
            <li>
                характеристики стеклопакета.
            </li>
        </ul>
    </p>
    <p>
        Если с данными показателями вы определились, то дальше окна из поливинилхлорида следует выбирать в несколько этапов и по некоторым свойствам.
    </p>
    <p>
        <b>Монтаж.</b> <br>
        Материал, используемый при создании ПВХ окон отличается неплохими свойствами, с помощью которых возможно создать действительно хорошую конструкцию высокой прочности. Поэтому обеспечить это возможно благодаря наличию правильно сконструированного узла соединения оконной конструкции. Хорошее окно должно иметь узел, сделанный по толщине стены, это предотвратит появление откоса и создаст более надежную и долговечную оконную конструкцию. Но ее качество не будет соответствующим, если работу выполняют не профессионалы, желательно монтаж должен проводиться сотрудниками фирмы, продающей окна.
    </p>
    <p>
        <b>Шумоизоляция.</b> <br> 
        Главное преимущество любого ПВХ окна в возможности создать идеальную шумоизоляцию, с которой вы не будете слышать посторонний шум на улице. Следовательно, данный параметр зависит от выбранной толщины стекол и камер.
    </p>
    <p>
        <b>Теплозащита.</b> <br> 
        Данный показатель в нашей стране достаточно важен, производители предлагают разные характеристики окон, отличающихся теплозащитой. Наиболее востребованный трехкамерный профиль, предлагается большинством компаний. Важным параметром, который придется соблюдать, является температура внутреннего стекла, она не должна быть ниже 15 градусов. Поэтому если вы выбираете окна ПВХ, цены могут отличаться в зависимости от потребностей.
    </p>
    <p>
        <b>Герметичность.</b> <br> 
        Дополнительной теплозащитой станет хорошая герметичность окна по периметру. Качественное окно должно обладать минимум 2-мя уплотнителями по периметру.
    </p>
    <p>
        <b>Комфорт.</b> <br> 
        Качественное ПВХ окно должно быть максимально защищенным от влаги, грязи, их не нужно красить, а помыть окно можно за пару минут. Дабы обеспечить лучшее проветривание пластиковые окна от производителя могут открываться несколькими способами.
    </p>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.gp {
    width: 90%;
    margin: 8vh auto;
}
h1 {
    font-size: 2vw;
}
.iml {
    float: left;
    width: 30vw;
    padding: 3vw;
}
p, li {
    font-size: 1vw;
    text-align: justify;
}

@media (max-width: 767px) {

    h1 {
        font-size: 23px;
    }
    .iml {
        width: 100%;
    }
    p, li {
        font-size: 14px;
    }
}
</style>