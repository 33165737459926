<template>
  <div class="vac">
    <h1>Вакансии</h1>
    <div class="row row-cols-1 row-cols-md-2 g-2">
        <div class="card">
            <div class="card-body">
                <h5 class="card-title">
                    Оператор на сварной станок
                </h5>
                <p class="card-text">
                    Кандидаты приглашаются на собеседование
                </p>
            </div>
            <img src="../assets/pro5.jpg" class="card-img-top" alt="">
        </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.vac {
  width: 90%;
  margin: 8vh auto;
}
h1 {
  font-size: 2vw;
  margin-bottom: 3vh;
}
p {
  margin-top: 2vw;
  font-size: 1.1vw;
  text-align: justify;
}
.row {
    margin-top: 3vh;
}
.card {
    width: 20%;
    margin-right: 20px;
}
.card-title {
    font-size: 1.7vw;
}
.card-img-top {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}
img {
    margin-bottom: 5px;
}

@media (max-width: 767px) {

    h1 {
        font-size: 23px;
    }
    p {
        font-size: 14px;
    }
    .card {
        width: 100%;
    }
    .card-title {
        font-size: 19px;
    }

}
</style>