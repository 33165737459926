<template>
  <div class="gp">
    <h1>Оконный профиль Grain-Lider 58</h1>
    <p>
        <img class="iml" alt="трехкамерные окна" src="../assets/grain2.jpg">
        Классическая оконная трехкамерная система <b>Grain-Lider</b> класса «А». Обладает высокими эксплуатационными характеристиками, обеспечивая надежность, прочность, тепло- и звукоизоляцию. Это позволяет использовать <b>Grain-Lider</b> для жилых, офисных помещений, детских и медицинских учреждений.
        
    </p>
    <p>
        Удаленность фурнитурного паза 13 мм позволяет устанавливать противовзломную фурнитуру.
    </p>
    <p>
        Дополнительные усилительные перегородки, технологические выступы во внутренних камерах профиля увеличивают прочность крепления запорных и несущих элементов, сокращают количество мостиков холода.
    </p>
    <p>
        Благодаря универсальности внутренней геометрии рамы появилась возможность установки замкнутого армирования. Это позволяет изготавливать нестандартные конструкции, безопасные в эксплуатации (при условии соблюдения статистических расчетов).
    </p>
    <p>
        <b>Свойства.</b><br>
        Монтажная ширина - 58 мм <br>
        Максимальная толщина стеклопакета - до 38 мм <br>
        Количество камер - 3. <br>
        Коэффициент сопротивления теплопередаче, м2°С/Вт - 0,8(с армирующим усилителем). <br>
        Герметичность контуров уплотнения - соответствуют классу А. <br>
        Уплотнители черного и серого цвета. <br>
        Количество контуров уплотнения - 2. <br>
        Срок службы - более 40 лет.
    </p>
    <p>
        <b>Преимущества.</b><br>
        Морозостойкое исполнение. Экологичность и пожаробезопасность.
    </p>
    <p>
        <b>Дизайн.</b><br>
        Классический вид пластикового окна.
    </p>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.gp {
    width: 90%;
    margin: 8vh auto;
}
h1 {
    font-size: 2vw;
}
.iml {
    float: left;
    width: 30vw;
    padding: 3vw;
}
p {
    font-size: 1vw;
    text-align: justify;
}

@media (max-width: 767px) {

    h1 {
        font-size: 23px;
    }
    .iml {
        width: 100%;
    }
    p {
        font-size: 14px;
    }
}
</style>