<template>
  <div class="gp">
    <h1>Ламинированные пластиковые окна</h1>
    <p>
        <img class="iml" alt="ламинированные пластиковые окна" src="../assets/lamin.jpg">
        Стандартные пластиковые окна не отличаются особым стилем, их выполняют в одинаковом дизайне. Но некоторые интерьеры такие окна будут только портить, а значит придется обратиться за новым решением в данной области — ламинированные пластиковые окна. Часто ламинированные окна представлены в имитацию под дерево или просто в цветных рамках. Также обратите внимание, что большинство компаний-производителей предлагает различные стили окон, поэтому вам остается подобрать наиболее оптимальный. Часто интерьерные решения стараются сочетать будущий стиль окна с определенной частью помещения, например, по цвету окно будет сочетаться с линолеумом или мебелью.
    </p>
    <p>
        Ламинирование окон представляет собой покрытие оконного профиля пленкой определенного цвета и фактуры. Стандартно принято использовать ламинирование под различные породы деревьев. Пленка, используемая в пластиковых окнах, не боится ультрафиолетовых лучей и внешних воздействий, поэтому смело устанавливается на любое окно вне зависимости от типа вашего помещения. Со временем пленка не будет выцветать или облазить, поэтому решение выбрать особое оформление окон ничуть не повлияет на качество продукции. Также стоит упомянуть, что стандартно существует два варианта отделки под дерево — золотой дуб, дуб рустикальный, махагони, темный и натуральный дуб. Каждый из них отличается оттенком и представлен в максимально похожем стиле, а значит обеспечит надлежащее качество и впишется в интерьер помещения. Если вам нужна нестандартная установка окон ПВХ, цена и другие условия можно изучить на нашем сайте.
    </p>
    <p>
        Также окна ПВХ могут отличаться по нанесению покрытия. Можно выбрать одно или двухстороннее ламинирование, например, с внешней стороны окно выглядит под дерево, а с внутренней имеет синий оттенок. Процедура ламинирования достаточно проста, но требует особых навыков. На пластиковую основу накатывается декоративная пленка определенного цвета, процесс выполняется под сильным давлением и высокой температурой. Далее производитель ждет определенное время для того, чтобы поверхность тщательно закрепилась и пленка не деформировалась. Пластиковые окна в Москве с ламинированным стилем станут идеальным решением для большинства покупателей. Ведь интерьер играет важную роль в любом помещении, а широкий выбор даст возможность создать идеальный вариант.
    </p>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.gp {
    width: 90%;
    margin: 8vh auto;
}
h1 {
    font-size: 2vw;
}
.iml {
    float: left;
    width: 30vw;
    padding: 3vw;
}
p {
    font-size: 1vw;
    text-align: justify;
}

@media (max-width: 767px) {

    h1 {
        font-size: 23px;
    }
    .iml {
        width: 100%;
    }
    p {
        font-size: 14px;
    }
}
</style>