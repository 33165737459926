<template>
  <div class="gp">
    <h1>Почему пластиковые окна потеют?</h1>
    <p>
        <img class="iml" alt="ламинированные пластиковые окна" src="../assets/pot.jpg">
        Металлопластиковые окна стали отличной альтернативой деревянным моделям, что подтверждается регулярным использованием подобных технологий в помещениях разного типа. Но вне зависимости от материала окна, у обеих вариантов есть важный недостаток — окна часто запотевают. На поверхности окон часто появляется конденсат, в результате которого не только ухудшается видимость, частая влажность увеличивает вероятность появления грибка и плесени по периметру окна. В крайнем случае происходит постепенное увлажнение всей строительной конструкции, поэтому важно следить за тем, чтобы пластиковые окна были максимально сухими.
    </p>
    <p>
        Стоимость пластиковых окон не особо влияет на появление конденсата, намного важней следовать правилам эксплуатации, где указано, что температура воздуха в помещении должна не быть ниже отметки +18°С. Если показатель ниже, не стоит удивляться частому запотеванию окон и появлению плесени. В большей степени конденсат образуется на внутренней нижней стороне стеклопакета, именно там наиболее низкая плотность холодного воздуха. Результатом такого образования является тот факт, что нижняя часть пластикового окна всегда наиболее холодная.
    </p>
    <p>
        Но часто не только хозяин является виновником запотевания окон, часто вина лежит на производителе и людях, которые занимались установкой. Например, вам установили холодное пластиковое окно, не предусмотрев, что его сопротивление теплоотдаче не соответствует требуемым параметрам. В другом случае холодный воздух будет попадать из-за некачественной герметизации и применения дешевых герметиков и уплотнителей. Важно проверять данные параметры, иначе вам не избежать регулярному запотеванию. Качественные пластиковые окна, размеры и цены на них представлены на нашем сайте, мы производим только правильные окна, соблюдая все технические требования.
    </p>
    <p>
        Если проблема все же имеется, устранить ее можно несколькими способами:
        <ul>
            <li>
                используйте температурный клапан, который монтируется в профиль, он уменьшит влажность воздуха в помещении;
            </li>
            <li>
                проветривайте комнату не реже 2 раз в день длительностью по 10 минут;
            </li>
            <li>
                если батареи отопления недостаточно циркулируют теплый воздух, сократите ширину подоконника.
            </li>
        </ul>
    </p>
    <p>
        Наличие конденсата на пластиковых окнах дело частое, поэтому не стоит так удивляться. Если вы еще не приобрели окна, но хотите наиболее оптимальный вариант, обратите внимание на 5 или 6-ти камерные профили.
    </p>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.gp {
    width: 90%;
    margin: 8vh auto;
}
h1 {
    font-size: 2vw;
}
.iml {
    float: left;
    width: 30vw;
    padding: 3vw;
}
p, li {
    font-size: 1vw;
    text-align: justify;
}

@media (max-width: 767px) {

    h1 {
        font-size: 23px;
    }
    .iml {
        width: 100%;
    }
    p, li {
        font-size: 14px;
    }
}
</style>