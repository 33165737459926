<template>
  <div class="gp">
    <h1>Стеклопакеты для пластиковых окон</h1>
    <p>
        <img class="iml" alt="стеклопакеты для пластиковых окон" src="../assets/stek.jpg">
        Когда дело подходит квыбору пластиковых окон, большинство людей задумываются о различных характеристиках и свойствах окна. И многие считают, что не нужно уделять много внимания такой детали как «стеклопакет», ошибочно выбирая первый предложенный вариант. Также многие путают понятия окно и стеклопакет, ведь принято правильно называть «пластиковое окно со стеклопакетом». Новая технология производства позволяет создавать максимально прочное стекло с особыми характеристиками, покупатель уже выбирает количество стекол в своем окне. Стандартно большинство производителей монтируют в двухкамерное окно три стекла, что вполне оптимальное решение для погодных условий России. Пластиковые окна от производителя всегда учитывают погодные ситуации, а также требования покупателя к шумоизоляции, поэтому возможно изготовить окна на заказ с нужным количеством стекол.
    </p>
    <p>
        Двухкамерные пластиковые окна сделаны с толщиной стекол по 4 мм, показатель толщины стеклопакета уже зависит от выбранных свойств всего ПВХ окна. Следовательно, чем лучше вам нужна шимоизоляция в помещении, тем толще нужны стекла, например, 6 мм каждое. Однокамерные стеклопакеты состоят всего из 2 стекол стандартной толщины, используются в местах без необходимости особой шумоизоляции.
    </p>
    <p>
        <b>Производство стеклопакетов</b> <br>
        Создание максимально прочных и звукоизоляционных стекол возможно при выполнении четкой технологии с применением определенного оборудования. На первом этапе стекла режут одинаковыми частями, затем их полностью проверяют на наличие трещин и прочих дефектов. Второй этап — установка между стекол специальных дистанционных рамок. Рамки представляют собой алюминиевый профиль, внутри которого расположился силикагель. Данное вещество помогает поглощать влагу между стеклами. Последний третий этап — обжим стеклопакета на прессе для лучшей прочности и нанесение по периметру окна тиокола, особо сильного герметика. Дальше готовый стеклопакет можно устанавливать в конструкцию пластикового окна.
    </p>
    <p>
        Важно знать, вы выбирая установку пластиковых окон, цена будет зависеть от множества показателей, в частности характеристик стеклопакета. Особую роль при производстве играет процесс герметизации, важно придерживаться точных показателей и использовать только качественные комплектующие и герметики. В результате, заказав прочное и шумозащитное окно, вы получаете ряд преимуществ за вполне доступную цену.
    </p>
    <img class="iml2" alt="стеклопакеты для пластиковых окон" src="../assets/stek2.jpg">
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.gp {
    width: 90%;
    margin: 8vh auto;
}
h1 {
    font-size: 2vw;
}
.iml {
    float: left;
    width: 30vw;
    padding: 3vw;
}
p {
    font-size: 1vw;
    text-align: justify;
}

@media (max-width: 767px) {

    h1 {
        font-size: 23px;
    }
    .iml, .iml2 {
        width: 100%;
    }
    p {
        font-size: 14px;
    }
}
</style>